const messages = {
  zh: {
    home: {
      text: '首页',
      title: '寰碳科技',
      title1: '',
      title2: '团队成员',
      title3: '合作伙伴',
      content: '寰碳科技致力打造世界领先的环境、气候与能源信息产品与咨询服务平台，通过高度专业化的资讯传播、洞见输出、咨询服务等...',
      content1: '寰碳科技致力打造世界领先的环境、气候与能源信息产品与咨询服务平台，通过高度专业化的资讯传播、洞见输出、咨询服务等，助力“双碳”背景下的企业升级与个人发展。我们的创始人均来自世界顶尖学府或高新企业，带领两个独立且相互关联的团队：',
      content2: '“零碳加”咨询团队：通过搭建世界级的数据信息平台，基于能源转型、碳中和大背景下的多样化需求，为组织和个人提供涵盖战略、管理及技术领域的专业、个性化气候咨询服务。',
      content3: '"大声谈"播客团队：通过声音和传播媒介，实现气候科普并帮助有志于环境和气候研究的青年更好的实现自我定位，职业指导，社会层面价值；',
      content4: '汇青年之声，聚时代之力，我们站在国际交流与合作的前沿，构建多元的深度对话，持续深耕专业价值输出，助力碳中和，实现新发展。',
      content5: '寰碳科技由一群来自多元背景、具有全球视野的有志青年发起，致力于促进气候问题对社会各行业影响的探讨，推动形成更为高效、先进的解决方案，助力个人与组织在“双碳”背景下实现快速转型与可持续发展。',
      footer: '寰碳科技 版权所有',
    },
    about: {
      text: '关于我们',
    },
    podcast: {
      text: '播客',
      title: '大声谈',
      title1: '媒体链接',
      title2: '博客分类',
      content1: '「大声谈」是国内一档聚焦探讨绿色发展背景下青年机遇的播客节目。在这里，我们谈气候正义，谈青年机会，谈职业发展。大声谈，弹走碳碳碳！',
      content2: '联系我们，分享您的洞见！邮箱地址：',
      team: '播客团队',
      search: '搜索',
      point1: '知识分享',
      point2: '职业发展',
      point3: '中外对话',
    },
    news: {
      text: '新闻',
    },
    contact: {
      text: '联系我们',
      title: '联系我们',
      title1: '加入我们',
      title2: '请联系我们',
      content1: '如果您有更多想要了解的信息，或想与我们进行合作交流，请通过邮箱联系我们。',
      content2: '寰碳科技致力于打造世界领先的环境、气候与能源信息产品与咨询服务平台。我们的创始人均来自世界顶尖学府或高新企业。加入我们，让我们一同助力“双碳”背景下的企业升级与个人发展。',
      content3: '简历投递方式：发送您的简历到',
    },
    zcp: {
      text: '零碳加',
      title: '零碳加',
      subtitle: '零碳加咨询',
      title1: '背景介绍',
      title2: '发展愿景',
      title3: '问卷调查',
      title4: '成员分布',
      content1: '气候变化作为人类面临的全球性挑战，对能源、金融、安全、健康等多个领域产生了深刻影响。为了实现更好的气候治理，世界各国政府、企业、社会组织等相关方广泛开展了跨部门、多层次的合作。当前，在我国“碳中和”背景下，气候变化及其在政策规制方面的变化深切地影响着包括企业、非政府组织和地方政府在内地众多群体，在生产、经营、技术等众多领域产生了形式多样的新要求和新挑战：',
      content2: '应对提高适应能力，模式转换和技术创新水平的发展新要求，需要更具针对性的咨询策略和解决方案。随着政策的不断发展，新兴技术的出现以及消费者行为的转变，在公共部门和企业组织中涌现的新需求也面临有效匹配的难题。',
      content3: '基于此，专业的服务咨询及精准的资源匹配能够帮助各方提升沟通效率，节约投资成本，从而协助推进政策落地，助力企业平稳转型，最终实现低碳经济与社会的健康发展。',
      content4: '我们致力于建立一个开创性的全球网络，以汇聚多方努力应对气候变化。团队将平台定位为气候相关目标和解决方案的智能撮合者。我们正在建立一个以顶尖学者为支撑的信息中心，为尖端技术创新、最新研究应用和行业发展提供细致的定制化解决方案。',
      content5: '该平台 (Zero carbon plus network 零碳加咨询) 旨在通过需求匹配和定制咨询，为诸如碳战略规划、气候金融管理和绿色技术突破等领域本地化气候相关解决方案的提供者和寻求者，帮助加快企业和组织的发展、转移和升级。走向更可持续的发展轨迹。',
      content6: '目前，我们的成员分布在亚洲、美洲、欧洲的十几个城市',
      content7: '诚邀您参与本问卷调研，帮助我们更好的了解当前低碳转型的大背景下您和您所在机构关切的核心问题，参与建设一个开创性的全球网络，共同应对气候变化！',
      team: '咨询团队',
      btn1: '填写',
      point1: '需求分析',
      point2: '战略设计',
      point3: '跨领域解决方案',
      point4: '经营改革：减排的政策规定下，经营模式的升级改造需求攀升',
      point5: '产业转型：低碳转型的宏观要求下，生产方式亟待转变，以实现更高效能的可持续发展',
      point6: '技术升级：“双碳”目标下，创新技术的指引是实现行业突破的关键',
    },
    city: {
      beijing: '北京',
      shanghai: '上海',
      hongkong: '香港',
      puchong: '蒲种',
      baltimore: '巴尔的摩',
      newyork: '纽约',
      newhaven: '纽黑文',
      amsterdam: '阿姆斯特丹',
      krakow: '克拉科夫',
      stockholm: '斯德哥尔摩',
      london: '伦敦',
      york: '约克',
      cambridge: '剑桥',
    },
    cop26: {
      title: 'COP26 活动日程',
      title1: '活动日',
      title2: '播客主题',
      day1: '11月2日',
      day2: '11月3日',
      day3: '11月4日',
      day4: '11月5日',
      day5: '11月6日',
      day6: '11月7日',
      day7: '11月8日',
      day8: '11月9日',
      day9: '11月10日',
      day10: '11月11日',
      day11: '11月12日',
      event1: '中国企业的气候行动如何助力全球实现1.5度温控目标？',
      event2: '资金日：悬而未决的第六条会在本届会议中一锤定音吗？',
      event3: '能源日：如何面对向可持续能源系统转型过程中的挑战？',
      event4: '公众赋能日：全球青年零碳未来峰会',
      event5: '自然日：基于自然的解决方案相比工业减碳效果有多大？',
      event6: '场外公众活动实采',
      event7: '气候适应与损失损害日：气候适应是值得关心的议题吗？',
      event8: '性别平等与科技创新日：女性参与和创新为何意义重大？',
      event9: '交通日：电动方程式(FE)赛事如何助力碳中和目标的实现？',
      event10: '社区日：从社区到城市，我们需要什么样子的气候行动？',
      event11: '终章：COP26的谈判结果是否符合各方预期，令大家满意？',
    },
  },
  en: {
    home: {
      text: 'HOME',
      title: 'Zero Carbon Plus Technology',
      title1: 'UNIFY FOR CHANGE',
      title2: 'THE TEAM MEMBERS',
      title3: 'OUR PARTNER',
      content: 'Zero Carbon Plus Technology is committed to building a world-leading platform for climate information exchange, knowledge sharing and tailored consultation services.',
      content1: 'Zero Carbon Plus Technology is committed to building a world-leading platform for climate information exchange, knowledge sharing and tailored consultation services. Focusing on information dissemination, content production and consultation needs, the ZCP Tech aims to help in the upgrading and development of enterprises under the background of China’s 2030 emission peak and 2060 carbon neutrality targets. Our founders come from the world\'s top universities and leading high-tech companies.Currently ZCP tech has two teams with respective focus:',
      content2: '"Zero Carbon Plus Network (ZCP Network)" consulting team: the Network provides professional and tailored consulting service by building a world-class data platform. Powered by leading technology, the platform will intelligently support multiple types of services under the diverse demands in line with China\'s "Double Carbon" goals by 2030 and 2060. ',
      content3: '"Let’s TalC" podcast team: the team is specialized in producing climate-related podcast programs, sharing environmental knowledge and initiating indepth exchanges around industry trends. Also through programs providing career guidance, young people with keen interest in environmental and climate research could be better navigated to identify their self-position and achieve personal goals while contributing greater value to the society',
      content4: 'With the vision of "amplifying the voices of the youth and shaping the trends of the time", we stand at the forefront of international exchanges and cooperation in the climate field. By building up more diversified and in-depth dialogues, and advanced professional services, we will spare no effort in navigating the steps towards carbon neutrality and a greener future for mankind.',
      content5: 'We are a group of young professionals from a diverse background who are committed to facilitating the achievement of climate ambitions.',
      footer: 'Zero Carbon Plus Technology All Rights Reserved',
    },
    about: {
      text: 'ABOUT US',
    },
    podcast: {
      text: 'PODCAST',
      title: 'Let\'s TalC',
      title1: 'The Media Links',
      title2: 'Podcasts',
      content1: '「Let’s TalC」is a Chinese podcast focusing on youth opportunities in the context of green industries development. By producing climate-related podcast programs, the team shares environmental knowledge and help young people who are interested in environmental and climate research to better realise their self-positioning, providing career guidance, and achieving social value Let’s TalC!',
      content2: 'Please contact us to share your insight! Email address：',
      team: 'podcast team',
      search: 'Search',
      point1: 'Knowledge Sharing',
      point2: 'Career Development',
      point3: 'International Dialogue',
    },
    news: {
      text: 'NEWS',
    },
    contact: {
      text: 'CONTACT',
      title: 'CONTACT US',
      title1: 'JOIN US',
      title2: 'KEEP IN TOUCH WITH US',
      content1: 'If you want to know more information, or to cooperate with us, please contact us through email.',
      content2: 'Zero Carbon Plus Technology is committed to building a world-leading platform for climate information exchange, knowledge sharing and tailored consultation services. Our founders come from the world\'s top universities and leading high-tech companies. Please join in us to make a difference in the upgrading and development of enterprises under the background of China’s 2030 emission peak and 2060 carbon neutrality targets.',
      content3: 'Resume delivery: Send your resume to',
    },
    zcp: {
      text: 'ZCP NETWORK',
      subtitle: 'Zero Carbon Plus Network',
      title: 'ZCP Network',
      title1: 'BACKGROUND',
      title2: 'OUR MISSION',
      title3: 'SURVEY',
      title4: 'Members Areas',
      content1: 'Climate change and the impact of relevant policies or regulations regarding carbon neutrality bring challenges and opportunities for various stakeholders including enterprises, NGOs and local governments. Diverse demands have thus emerged, requiring tailored solutions for adaptation, transfer and innovation such as',
      content2: 'Currently, however, as the environment changes, regulations evolve, new technologies emerge, and customer behavior shifts, the crux facing both the public and private sector lies in the effective and efficient match between the problem solvers and the issuers seeking the optimal solutions  — and whether the offered proposals could truly work in the end.',
      content3: 'Without reliable navigation, relevant players may be exposed to huge gaps in terms of climate-related risks and opportunities at the cost of wasted investment and invalid communications, ultimately resulting in a more bumpy transition to a low-carbon economy at the state level.',
      content4: 'We are committed to establishing a pioneering global network for convergence of multi-front effort in response to climate change. Positioned as an intelligent match-maker of climate-related targets and solutions, we are building a first-in-kind information hub aggregating cutting-edge technological innovations, up-to-date research-based applications and industry solutions underpinned by top notch scholars, consultancies and think tanks.',
      content5: 'Through indepth exchanges and tailored consultation, the Network aims to connect providers and seekers of localized climate-related solutions in such fields as carbon strategy planning, climate finance management and green technology breakthrough to help speed up the development, transfer and upgrading of enterprises and organizations towards a more sustainable trajectory.',
      content6: 'Currently, our members are spread in eleven cities throughout Asia, America and Europe.',
      content7: 'Please fill in this questionnaire, in helping us identify the key issues and concerns of you and your team under the general goal in combating climate change！',
      team: 'consulting team',
      btn1: 'Write',
      point1: 'Demand Analysis',
      point2: 'Strategic Design',
      point3: 'Cross-domain Solution',
      point4: 'Restructuring the business in fitting the mitigation needs',
      point5: 'Upgrading operation capacity towards a more sustainable end',
      point6: 'Renewing and breaking the threshold of tech gaps',
    },
    city: {
      beijing: 'Beijing',
      shanghai: 'Shanghai',
      hongkong: 'Hong Kong',
      puchong: 'Puchong',
      baltimore: 'Baltimore',
      newyork: 'New York',
      newhaven: 'New Haven',
      amsterdam: 'Amsterdam',
      krakow: 'Krakow',
      stockholm: 'Stockholm',
      london: 'London',
      york: 'York',
      cambridge: 'Cambridge',
    },
    cop26: {
      title: 'Our COP26 Agenda',
      title1: 'COP DAY',
      title2: 'PODCAST THEME',
      day1: 'November 2nd',
      day2: 'November 3rd',
      day3: 'November 4th',
      day4: 'November 5th',
      day5: 'November 6th',
      day6: 'November 7th',
      day7: 'November 8th',
      day8: 'November 9th',
      day9: 'November 10th',
      day10: 'November 11th',
      day11: 'November 12th',
      event1: 'How does China’s Corporate Climate Action Contribute to the Paris Agreement 1.5 Degree Target?',
      event2: 'Will the Unresolved Article 6 be Finalized in this COP?',
      event3: 'How to Solve the Challenges in Sustainable Energy Transition?',
      event4: 'Global Youth Summit on Net-Zero Future',
      event5: 'Do We Need NBS if Industrial Carbon Reduction is more Effective?',
      event6: 'Climate Actions in Glasgow',
      event7: 'Why is Climate Adaptation Worthy of Attention?',
      event8: 'What is the Role of Female’s Participation and Innovation in Climate Action?',
      event9: 'How does the Formula E event Accelerate to Achieve the Goal of Carbon Neutrality?',
      event10: 'From Communities to Cities, What Kind of Climate Action Do We Need?',
      event11: 'Results of COP26, Does it Meet the Expectations of All Parties and Satisfy Everyone?',
    },
  },
}

export default messages
